import styles from './ContentLayout.module.scss'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import PreviousNext from '@/components/PreviousNext'

function isArray(children) {
  return Array.isArray(children)
}

function ContentLayoutRoot({ asideTop = false, className, children, ...props }) {
  const hasMultipleChildren = isArray(children)
  const hasAside = hasMultipleChildren
    ? children.map((child) => child?.type.name).includes('Aside')
    : children.type.name === 'Aside'

  return (
    <div
      className={cn(styles['root'], {
        [styles['root--full-width']]: !hasAside,
        [styles['root--aside-top']]: asideTop,
        [className]: className,
      })}
      {...props}
    >
      {children}
    </div>
  )
}

function MainRoot({ previousUrl, nextUrl, className, children, ...props }) {
  const hasPreviousAndNext = previousUrl && nextUrl

  return (
    <main
      className={cn(styles['main'], { [styles['main--with-nav']]: hasPreviousAndNext, [className]: className })}
      {...props}
    >
      {children}

      {hasPreviousAndNext && (
        <section className={cn(styles['prev-next'])}>
          <div>
            <PreviousNext
              previousUrl={previousUrl}
              nextUrl={nextUrl}
            />
          </div>
        </section>
      )}
    </main>
  )
}

function IntroHeaderRoot({ className, children, ...props }) {
  const hasMultipleChildren = isArray(children)
  const hasMedia = hasMultipleChildren
    ? children.map((child) => child?.type.name).includes('Media')
    : children.type.name === 'Media'

  return (
    <section
      className={cn(styles['intro-header'], {
        [styles['intro-header--with-media']]: hasMedia,
        [className]: className,
      })}
      {...props}
    >
      {children}
    </section>
  )
}

function Content({ className, children, ...props }) {
  return (
    <div
      className={cn(styles['intro-header__content'], { [className]: className })}
      {...props}
    >
      {children}
    </div>
  )
}

function Media({ className, children, ...props }) {
  const mediaRef = useRef(null)

  useEffect(() => {
    function setContentLayoutHeaderHeightVariable() {
      const contentLayoutAside = document.querySelector('[data-content-layout-aside]')
      if (!contentLayoutAside && !mediaRef.current) return

      const mediaHeight = mediaRef.current.offsetHeight
      const breadcrumbHeight = document.querySelector('[aria-label="Breadcrumb"]')?.offsetHeight || 0
      contentLayoutAside.style.setProperty('--introheader-height', mediaHeight + breadcrumbHeight)
      contentLayoutAside.dataset.hasMedia = true
    }

    setContentLayoutHeaderHeightVariable()

    window.addEventListener('resize', setContentLayoutHeaderHeightVariable)
    return () => window.removeEventListener('resize', setContentLayoutHeaderHeightVariable)
  }, [])

  return (
    <div
      className={cn(styles['intro-header__media'], { [className]: className })}
      ref={mediaRef}
      {...props}
    >
      <div>{children}</div>
    </div>
  )
}

function Aside({ className, children, ...props }) {
  return (
    <aside
      data-content-layout-aside
      className={cn(styles['aside'], { [className]: className })}
      {...props}
    >
      {children}
    </aside>
  )
}

function Section({ className, children, ...props }) {
  return (
    <section
      className={cn(styles['section'], { [className]: className })}
      {...props}
    >
      {children}
    </section>
  )
}

const IntroHeaderLayout = Object.assign(IntroHeaderRoot, { Content, Media })
const MainLayout = Object.assign(MainRoot, { IntroHeader: IntroHeaderLayout })
const ContentLayout = Object.assign(ContentLayoutRoot, { Main: MainLayout, Aside, Section })

export default ContentLayout
