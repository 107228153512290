import styles from './PreviousNext.module.scss'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import Link from 'next/link'

function PreviousNext({ previousUrl, nextUrl, className, ...props }) {
  if (!previousUrl && !nextUrl) return null

  return (
    <div
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      {previousUrl && (
        <Link href={previousUrl}>
          <ArrowLeftIcon />
          Vorige
        </Link>
      )}

      {nextUrl && (
        <Link href={nextUrl}>
          Volgende
          <ArrowRightIcon />
        </Link>
      )}
    </div>
  )
}

export default PreviousNext
