import styles from './404.module.scss'
import cn from 'classnames'
import Button from '@/components/Button/Button'
import AppLayout from '@/layouts/AppLayout'
import ContentLayout from '@/layouts/ContentLayout'
import LayoutService from '@/services/LayoutService'

function Custom404({ layoutData }) {
  return (
    <AppLayout data={layoutData}>
      <ContentLayout className={cn(styles['root'])}>
        <ContentLayout.Main>
          <ContentLayout.Main.IntroHeader className={cn(styles['root__introheader'])}>
            <h1>Pagina kan niet gevonden worden (404)</h1>
            <p>
              Dit kan verscheidene oorzaken hebben: de pagina bestaat niet (meer), er is een fout opgetreden of u heeft
              geen rechten tot de opgevraagde pagina.
            </p>

            <strong>Probeer één van de volgende oplossingen:</strong>
            <ul>
              <li>
                Ga naar onze{' '}
                <a
                  href="/"
                  title="Ga naar onze homepage"
                >
                  homepage
                </a>
                .
              </li>
              <li>Klik op de Vorige-knop van uw browser als u terug wilt naar de vorige pagina.</li>
              <li>Gebruik het menu op de deze pagina om uw weg te vervolgen.</li>
            </ul>
          </ContentLayout.Main.IntroHeader>

          <div className={cn(styles['root__buttons'])}>
            <Button
              href="/"
              color="yellow"
            >
              Naar homepage
            </Button>

            <Button
              title="Zoeken"
              href="/zoeken"
              outline
            >
              Zoeken
            </Button>
          </div>
        </ContentLayout.Main>
      </ContentLayout>
    </AppLayout>
  )
}

export async function getStaticProps() {
  const { data: layoutData } = await LayoutService().index()

  return { props: { layoutData } }
}

export default Custom404
